/* In your global CSS file or imported CSS module */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .backdrop-blur-lg {
    backdrop-filter: blur(15px);
  }
}
